import {
  Parallax,
  type ParallaxProps,
  ParallaxProvider,
} from "react-scroll-parallax";
import "./GodRays.css";

export const GodRays = ({
  offset = { top: -200, right: 0, bottom: 100, left: 0 },
}: {
  offset?: ParallaxProps["rootMargin"];
}) => {
  return (
    <ParallaxProvider>
      <Parallax
        rootMargin={offset}
        speed={-100}
        className="w-full h-full transition-bg"
      >
        <div
          className="godrays absolute -inset-[10px] will-change-auto"
          style={{ transform: "translateZ(0)" }}
        ></div>
      </Parallax>
    </ParallaxProvider>
  );
};
